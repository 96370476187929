import React, {Component, Fragment} from 'react';
import MenuBar from "../Components/MenuBar";
import Camera from "../Components/Camera";


class CameraPage extends Component {
    render() {
        return (
            <Fragment>
                <MenuBar/>
                <br/>
                <Camera/>
            </Fragment>
        );
    }
}

export default CameraPage;
