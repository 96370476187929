import React, {Component,Fragment} from 'react';
import MenuBar from "../Components/MenuBar";
import FaceMatch from "../Components/FaceMatch";

class FaceMatchPage extends Component {
    render() {
        return (
            <Fragment>
                <MenuBar/>
                <br/>
                <FaceMatch/>
            </Fragment>
        );
    }
}

export default FaceMatchPage;
