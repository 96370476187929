import React, {Component, Fragment} from 'react';
import {Container, Row, Col } from "react-bootstrap";
import JEELIZFACEEXPRESSIONS from "../WebGl/jeelizFaceExpressions.module"
import spinner from "../Assets/Image/spinner.svg";
import SweetAlert from "react-bootstrap-sweetalert";


class Movement extends Component {

     constructor() {
         super();
         this.state={
             cameraError:false,
             PreviewSpinner:"",
             spinner:spinner,
             FaceDetect:"...",
             MouthOpen:"...",
             MouthClose:"...",
             RightEyeOpen:"...",
             RightEyeClose:"...",
             LeftEyeOpen:"...",
             LeftEyeClose:"..."
         }
     }


    opnewebGlCamera=()=>{
        JEELIZFACEEXPRESSIONS.init({
             canvasId:"canvasID",
             NNCPath:"model2/",
             hysteresis:0.1,
            isMirror:true,
            callbackReady:(err)=>{
                 if (err){
                     this.setState({cameraError:true})
                     console.log(err)
                 }else{
                     this.setState({PreviewSpinner:"d-none"})
                     this.getMovement()
                 }
            }
        })
    }

    getMovement=()=>{
         setInterval(()=>{
             let result  = JEELIZFACEEXPRESSIONS.get_morphTargetInfluences();

             if (JEELIZFACEEXPRESSIONS.is_detected()){
                 this.setState({
                     FaceDetect:"YES"
                 })
                 let rightEye = result[8];
                 let leftEye = result[9]
                 let mouth = result[6]
                 this.rightEyeStatus(rightEye);
                 this.letEyeStatus(leftEye);
                 this.mouthStatus(mouth);
             }else{
                 this.setState({
                     FaceDetect:"...."
                 })
             }
         }, 1000)
    }

    rightEyeStatus=(rightEye)=>{
         if (rightEye >= 0.7){
             this.setState({
                 RightEyeOpen:"",
                 RightEyeClose:"YES",
             })
         }else{
             this.setState({
                 RightEyeOpen:"YES",
                 RightEyeClose:"...",
             })
         }
    }

    letEyeStatus=(leftEye)=>{
        if (leftEye >= 0.7) {
            this.setState({
                LeftEyeOpen:"...",
                LeftEyeClose:"YES"
            })
        }else{
            this.setState({
                LeftEyeOpen:"YES",
                LeftEyeClose:"..."
            })
        }
     }

    mouthStatus=(mouth)=>{
        if (mouth >= 0.3){
            this.setState({
                MouthOpen:"YES",
                MouthClose:"...",
            })
        }else{
            this.setState({
                MouthOpen:"...",
                MouthClose:"YES",
            })
        }
    }

    componentDidMount() {
        this.opnewebGlCamera()
    }

    popupMenu=()=>{
        if (this.state.cameraError){
            return(
                <SweetAlert danger title="Fail to open camera!allow camera open" onConfirm={this.reload} >
                </SweetAlert>
            );
        }
    }

    reload=()=>{
        window.location ="/";
    }


    render() {

        return (
            <Fragment>
              <Container>
                  <Row className="mt-5">

                      <Col className="text-center" md={6} sm={12} lg={6}>
                          <img className={this.state.PreviewSpinner+" w-50"} src={this.state.spinner}/>
                        <canvas className="canvasClass" id="canvasID"></canvas>
                      </Col>


                      <Col  md={6} sm={12} lg={6}>
                          <table className="table">
                              <tbody>
                                <tr>
                                    <td className="text-white">Face Detect</td>
                                    <td className="text-danger">{this.state.FaceDetect}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Mouth Open</td>
                                    <td className="text-danger">{this.state.MouthOpen}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Mouth Close</td>
                                    <td className="text-danger">{this.state.MouthClose}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Right Eye Open
                                    </td>
                                    <td className="text-danger">{this.state.RightEyeOpen}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Right Eye Close
                                    </td>
                                    <td className="text-danger">{this.state.RightEyeClose}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Left Eye Open</td>
                                    <td className="text-danger">{this.state.LeftEyeOpen}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Left Eye Close</td>
                                    <td className="text-danger">{this.state.LeftEyeClose}</td>
                                </tr>

                              </tbody>

                          </table>
                      </Col>

                  </Row>
              </Container>
                { this.popupMenu() }
            </Fragment>
        );
    }
}

export default Movement;
