import React, {Component, Fragment} from 'react';
import Switch from "react-bootstrap/Switch";
import {Route} from "react-router";
import ImageToTextPage from "../Pages/ImageToTextPage";
import SmartPlayerPage from "../Pages/SmartPlayerPage";
import FaceMatchPage from "../Pages/FaceMatchPage";
import MovementPage from "../Pages/MovementPage";
import CameraPage from "../Pages/CameraPage";

class AppRouting extends Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path="/" render={(props) => <CameraPage {...props} />}/>
                    <Route exact path="/ImageToText" render={(props) => <ImageToTextPage {...props} />}/>
                    <Route exact path="/SmartPlayer" render={(props) => <SmartPlayerPage {...props} />}/>

                    <Route exact path="/FaceMovement" render={(props) => <MovementPage {...props} />}/>
                    <Route exact path="/FaceMatch" render={(props) => <FaceMatchPage {...props} />}/>
                </Switch>
            </Fragment>
        );
    }
}

export default AppRouting;
