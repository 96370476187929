import React, {Component, Fragment} from 'react';
import {Container, Row, Col } from "react-bootstrap";
import JEELIZFACEEXPRESSIONS from "../WebGl/jeelizFaceExpressions.module"
import { Player, BigPlayButton } from 'video-react';


class SmartPlayer extends Component {

     constructor() {
         super();
         this.state={
             FaceDetect:"...",
         }
     }


    opnewebGlCamera=()=>{
        JEELIZFACEEXPRESSIONS.init({
             canvasId:"canvasID2",
             NNCPath:"model2/",
             hysteresis:0.1,
            isMirror:true,
            callbackReady:(err)=>{
                 if (err){
                     console.log(err)
                 }else{
                     this.getMovement()
                 }
            }
        })
    }

    getMovement=()=>{
         setInterval(()=>{
             let result  = JEELIZFACEEXPRESSIONS.get_morphTargetInfluences();

             if (JEELIZFACEEXPRESSIONS.is_detected()){
                 this.videoOpen()
             }else{
               this.videoClose()
             }
         }, 3000)
    }

    videoOpen=()=>{
        this.player.play()
    }

    videoClose=()=>{
         this.player.pause()
    }

    componentDidMount() {
        this.opnewebGlCamera()
    }


    render() {

        return (
            <Fragment>
              <Container>
                  <Row className="mt-5">

                      <Col className="text-center" md={12} lg={12}>
                          <Player ref={(player)=>{this.player = player}}>
                              <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                              <BigPlayButton position="center"/>
                          </Player>
                      </Col>


                      <Col  md={8}  lg={8}>
                          <canvas className="PlayerCanvasClass" id="canvasID2"></canvas>
                      </Col>

                  </Row>
              </Container>
            </Fragment>
        );
    }
}

export default SmartPlayer;
