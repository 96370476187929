import React, {Component, Fragment} from 'react';
import {Container, Row, Col } from "react-bootstrap";
import imagePlace from "../Assets/Image/imagePlaceholder.svg";
import WebCam from "react-webcam";
import SweetAlert from "react-bootstrap-sweetalert";
import * as faceapi from "face-api.js";
import Loader from "react-loader-spinner";

class Camera extends Component {

    constructor() {
        super();
        this.cameraRef = React.createRef();
        this.state = {
            mainPhoto : imagePlace,
            cameraError:false,
            ageAndGender:[],
            age:"",
            gender:"...",
            expression:"...",
            loader:false,
            faceExpression:[],
            faceLandMark:[],

            left_to_right_eye:"....",
            nose_to_left_mouth:"....",
            nose_to_right_mouth:"....",
            nose_to_left_eye:"....",
            nose_to_right_eye:"..."
        }
    }

    setLoader=()=>{
        if(this.state.loader === true){
            return (
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col md={12} sm={12} lg={12}>
                            <div className="overlay center-screen">
                                <Loader
                                    type="Circles"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        </Col>`
                    </Row>
                </Container>

            );
        }

    }

    onCapture=()=>{
        let photoBase24 = this.cameraRef.current.getScreenshot();
        this.setState(
            {mainPhoto: photoBase24}
        )
    }

    onCameraErr=()=>{
        this.setState({cameraError:true})
    }
    saveImage=()=>{
        let base24String = this.state.mainPhoto;
        let a = document.createElement("a");
        a.href = base24String;
        a.download = "WebCamImage.jpeg";
        a.click();
    }

    analysisImage=()=>{
        this.getAgeAndGender();
        this.getFaceExpression();
        this.getFaceLandMark();
    }

    reload=()=>{
        window.location ="/";
    }

    popupMenu=()=>{
        if (this.state.cameraError){
            return(
                <SweetAlert danger title="Fail to open camera!allow camera open" onConfirm={this.reload} >
                </SweetAlert>
            );
        }
    }

    getAgeAndGender=()=>{
        (async ()=>{
            this.setState({loader:true})
          await faceapi.nets.ssdMobilenetv1.loadFromUri("model1/")
            await faceapi.nets.ageGenderNet.loadFromUri("model1/")
            let image = document.getElementById("myImage");
           let getAgeGender =  await faceapi.detectAllFaces(image).withAgeAndGender()

             this.setState({ageAndGender:getAgeGender})
             this.setState({
                 age: parseInt(getAgeGender[0]["age"]),
                 gender:getAgeGender[0]["gender"]
             })
            this.setState({loader:false})
        })(

        )
    }

    getFaceExpression=()=>{
        (async ()=>{
            this.setState({loader:true})
            await faceapi.nets.ssdMobilenetv1.loadFromUri("model1/");
            await faceapi.nets.faceExpressionNet.loadFromUri("model1/")
            let image = document.getElementById("myImage");
            let result = await faceapi.detectAllFaces(image).withFaceExpressions();
            this.setState({faceExpression:result})
            this.setState({loader:false})

            let neutral= result[0]["expressions"]["neutral"];
            let happy= result[0]["expressions"]["happy"];
            let sad= result[0]["expressions"]["sad"];
            let angry= result[0]["expressions"]["angry"];
            let fearful= result[0]["expressions"]["fearful"];
            let disgusted= result[0]["expressions"]["disgusted"];
            let surprised= result[0]["expressions"]["surprised"];

            if (neutral> 0.9 && neutral < 1.2){
                this.setState({
                    expression: "Neutral"
                })
            }else if(happy> 0.9 && happy < 1.2){
                this.setState({
                    expression: "happy"
                })
            }else if(sad> 0.9 && sad < 1.2){
                this.setState({
                    expression: "sad"
                })
            }else if(angry> 0.9 && angry < 1.2){
                this.setState({
                    expression: "angry"
                })
            }else if(fearful> 0.9 && fearful < 1.2){
                this.setState({
                    expression: "fearful"
                })
            }else if(disgusted> 0.9 && disgusted < 1.2){
                this.setState({
                    expression: "disgusted"
                })
            }else if(surprised> 0.9 && surprised < 1.2){
                this.setState({
                    expression: "surprised"
                })
            }

        })(

        )
    }

    getFaceLandMark=()=>{
        (async ()=>{
            this.setState({loader:true})
            await faceapi.nets.ssdMobilenetv1.loadFromUri("model1/");
            await faceapi.nets.faceLandmark68Net.loadFromUri("model1/")

            let image =  document.getElementById("myImage");
            let getFaceLand = await faceapi.detectAllFaces(image).withFaceLandmarks();

            this.left_to_right_eye(getFaceLand);
            this.nose_to_left_mouth(getFaceLand);
            this.nose_to_right_mouth(getFaceLand);
            this.nose_to_left_eye(getFaceLand);
            this.nose_to_right_eye(getFaceLand);

            this.setState({
                faceLandMark:getFaceLand,
                loader:false
            })
        })()
    }

    left_to_right_eye=(landmarkArray)=>{

        let x1 = landmarkArray[0]["landmarks"]["_positions"][37]["_x"];
        let y1 = landmarkArray[0]["landmarks"]["_positions"][37]["_y"];

        let x2 = landmarkArray[0]["landmarks"]["_positions"][46]["_x"];
        let y2 = landmarkArray[0]["landmarks"]["_positions"][46]["_y"];

        let dist = Math.sqrt(  (Math.pow((x1-x2), 2))+ (Math.pow((y1-y2), 2)))
        this.setState({left_to_right_eye: dist.toString()})

    }

    nose_to_left_mouth=(landmarkArray)=>{


        let x1 = landmarkArray[0]["landmarks"]["_positions"][31]["_x"];
        let y1 = landmarkArray[0]["landmarks"]["_positions"][31]["_y"];

        let x2 = landmarkArray[0]["landmarks"]["_positions"][49]["_x"];
        let y2 = landmarkArray[0]["landmarks"]["_positions"][49]["_y"];

        let dist = Math.sqrt(  (Math.pow((x1-x2), 2))+ (Math.pow((y1-y2), 2)))
        this.setState({nose_to_left_mouth: dist.toString()})
    }

    nose_to_right_mouth=(landmarkArray)=>{


        let x1 = landmarkArray[0]["landmarks"]["_positions"][31]["_x"];
        let y1 = landmarkArray[0]["landmarks"]["_positions"][31]["_y"];

        let x2 = landmarkArray[0]["landmarks"]["_positions"][55]["_x"];
        let y2 = landmarkArray[0]["landmarks"]["_positions"][55]["_y"];

        let dist = Math.sqrt(  (Math.pow((x1-x2), 2))+ (Math.pow((y1-y2), 2)))
        this.setState({nose_to_right_mouth: dist.toString()})
    }

    nose_to_left_eye=(landmarkArray)=>{


        let x1 = landmarkArray[0]["landmarks"]["_positions"][31]["_x"];
        let y1 = landmarkArray[0]["landmarks"]["_positions"][31]["_y"];

        let x2 = landmarkArray[0]["landmarks"]["_positions"][37]["_x"];
        let y2 = landmarkArray[0]["landmarks"]["_positions"][37]["_y"];

        let dist = Math.sqrt(  (Math.pow((x1-x2), 2))+ (Math.pow((y1-y2), 2)))
        this.setState({nose_to_left_eye: dist.toString()})
    }

    nose_to_right_eye=(landmarkArray)=>{


        let x1 = landmarkArray[0]["landmarks"]["_positions"][31]["_x"];
        let y1 = landmarkArray[0]["landmarks"]["_positions"][31]["_y"];

        let x2 = landmarkArray[0]["landmarks"]["_positions"][46]["_x"];
        let y2 = landmarkArray[0]["landmarks"]["_positions"][46]["_y"];

        let dist = Math.sqrt(  (Math.pow((x1-x2), 2))+ (Math.pow((y1-y2), 2)))
        this.setState({nose_to_right_eye: dist.toString()})
    }

    render() {

        return (
            <Fragment>
              <Container>
                  <Row className="mt-5">
                      <Col className="text-center" md={6} sm={12} lg={6}>
                          <WebCam
                              onUserMediaError={ this.onCameraErr }
                              audio={false}
                              ref={this.cameraRef}
                              screenshotFormat="image/jpeg"
                              className="w-100" />
                          <button onClick={this.onCapture} className="mt-4 btn btn-info">Capture</button>
                      </Col>

                      <Col className="text-center" md={6} sm={12} lg={6}>
                          <img id="myImage" width="600" src={this.state.mainPhoto} alt="My Image"/>
                          <button onClick={this.saveImage} className="mt-4 btn btn-info mr-2">Save</button>
                          <button onClick={this.analysisImage} className="mt-4 btn btn-info">Analysis</button>
                      </Col>
                  </Row>
              </Container>

                <Container className="mt-5">
                    <Row>
                        <Col md={12} sm={12} lg={12}>
                            <table  className="table table-bordered table-striped text-info">
                                <thead>
                                   <th>Age</th>
                                   <th>Gender</th>
                                   <th>Expression</th>
                                   <th>Left_to_right_eye</th>
                                   <th>Nose_to_left_eye</th>
                                   <th>Nose_to_right_eye</th>
                                   <th>Nose_to_left_mouth</th>
                                   <th>Nose_to_right_mouth</th>
                                </thead>

                                <tbody className="text-danger text-center font-weight-bold">
                                  <td>{this.state.age}</td>
                                  <td>{this.state.gender}</td>
                                  <td>{this.state.expression}</td>
                                  <td>{this.state.left_to_right_eye}</td>
                                  <td>{this.state.nose_to_left_mouth}</td>
                                  <td>{this.state.nose_to_right_mouth}</td>
                                  <td>{this.state.nose_to_left_eye}</td>
                                  <td>{this.state.nose_to_right_eye}</td>
                                </tbody>
                            </table>

                        </Col>

                    </Row>
                </Container>
                { this.popupMenu() }
                { this.setLoader() }
            </Fragment>
        );
    }
}

export default Camera;
