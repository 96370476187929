import React, {Component,Fragment} from 'react';
import MenuBar from "../Components/MenuBar";
import SmartPlayer from "../Components/SmartPlayer";

class SmartPlayerPage extends Component {
    render() {
        return (
            <Fragment>
                <MenuBar/>
                <br/>
                <SmartPlayer/>
            </Fragment>
        );
    }
}

export default SmartPlayerPage;
