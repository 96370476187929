import React, {Component, Fragment} from 'react';
import ImageToText from "../Components/ImageToText";
import MenuBar from "../Components/MenuBar";

class ImageToTextPage extends Component {

    render() {
        return (
            <Fragment>
                <MenuBar/>
                <br/>
                <ImageToText/>
            </Fragment>
        );
    }
}

export default ImageToTextPage;
