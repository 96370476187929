import React, {Component, Fragment} from 'react';
import MenuBar from "../Components/MenuBar";
import Movement from "../Components/Movement";

class MovementPage extends Component {
    render() {
        return (
            <Fragment>
                <MenuBar/>
                <br/>
                <Movement/>
            </Fragment>
        );
    }
}

export default MovementPage;
