import React, {Component, Fragment} from 'react';
import {Container, Row, Col } from "react-bootstrap";
import imagePlace from "../Assets/Image/imagePlaceholder.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import * as faceapi from "face-api.js";
import Loader from "react-loader-spinner";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


class FaceMatch extends Component {

    constructor() {
        super();
        this.cameraRef = React.createRef();
        this.state = {
            cameraError:false,
            loader:false,
            firstImageSrc:imagePlace,
            secondImageSrc:imagePlace,
            firstImageDes:[],
            secondImageDes:[],
            distance:0,
            similarity:0,
            result:""

        }
    }

    setLoader=()=>{
        if(this.state.loader === true){
            return (
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col md={12} sm={12} lg={12}>
                            <div className="overlay center-screen">
                                <Loader
                                    type="Circles"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        </Col>`
                    </Row>
                </Container>

            );
        }

    }

    onCapture=()=>{
        let photoBase24 = this.cameraRef.current.getScreenshot();
        this.setState(
            {mainPhoto: photoBase24}
        )
    }

    onCameraErr=()=>{
        this.setState({cameraError:true})
    }
    saveImage=()=>{
        let base24String = this.state.mainPhoto;
        let a = document.createElement("a");
        a.href = base24String;
        a.download = "WebCamImage.jpeg";
        a.click();
    }

    analysisImage=()=>{
        this.getAgeAndGender();
        this.getFaceExpression();
        this.getFaceLandMark();
    }

    reload=()=>{
        window.location ="/";
    }

    popupMenu=()=>{
        if (this.state.cameraError){
            return(
                <SweetAlert danger title="Fail to open camera!allow camera open" onConfirm={this.reload} >
                </SweetAlert>
            );
        }
    }

    firstPhotoChange=()=>{
        let firstImage =   this.firstPhoto.files[0];
        let reader = new FileReader();
        reader.onload=(e)=>{
            this.setState({firstImageSrc:e.target.result})
        }
        reader.readAsDataURL(firstImage);
        this.getFirstImageDesCal();
        }

      getFirstImageDesCal=()=>{
          (async ()=>{
              this.setState({loader:true})
              await faceapi.loadSsdMobilenetv1Model("model1/");
              await faceapi.loadFaceLandmarkModel("model1/");
              await faceapi.loadFaceRecognitionModel("model1/")

              let firstPho = document.getElementById("firstImg");
              const result = await faceapi.detectSingleFace(firstPho).withFaceLandmarks().withFaceDescriptor();
              this.setState({
                  firstImageDes:result["descriptor"],
                  loader:false
              })

          })()
      }

    secondPhotoChange=()=>{
        let secondImage =   this.secondPhoto.files[0];
        let reader = new FileReader();
        reader.onload=(e)=>{
            this.setState({secondImageSrc:e.target.result})
        }
        reader.readAsDataURL(secondImage);
        this.getSecondImageDesCal();
    }

    getSecondImageDesCal=()=>{
        (async ()=>{
            this.setState({loader:true})
            await faceapi.loadSsdMobilenetv1Model("model1/");
            await faceapi.loadFaceLandmarkModel("model1/");
            await faceapi.loadFaceRecognitionModel("model1/")

            let secondPho = document.getElementById("secondImg");
            const result = await faceapi.detectSingleFace(secondPho).withFaceLandmarks().withFaceDescriptor();
            this.setState({
                secondImageDes:result["descriptor"],
                loader:false
            })

        })()
    }

    FaceMatchingResult=()=>{
          let firstImage = this.state.firstImageDes
           let secondImage =   this.state.secondImageDes

          let distance =  faceapi.euclideanDistance(firstImage, secondImage);
          let similarity = 1- distance;

          if (similarity > 0.55){
              this.setState({
                  result:"PASS"
              })
          }else{
              this.setState({
                  result:"FAIL"
              })
          }

          this.setState({
              distance:distance,
              similarity:similarity
          })

    }


    render() {

        return (
            <Fragment>
              <Container>
                  <Row className="mt-5">

                      <Col className="text-center" md={4} sm={12} lg={4}>
                          <img id="firstImg" className="w-100" src={this.state.firstImageSrc} alt="My Image"/>
                          <input onChange={this.firstPhotoChange} className="d-none" ref={(input)=> this.firstPhoto = input} type="file"/>
                          <button onClick={()=> {this.firstPhoto.click()}} className="mt-4 btn btn-info">Select First Photo</button>
                      </Col>

                      <Col className="text-center" md={4} sm={12} lg={4}>
                          <img id="secondImg" className="w-100" src={this.state.secondImageSrc} alt="My Image"/>
                          <input onChange={this.secondPhotoChange} className="d-none" ref={(input)=> this.secondPhoto = input} type="file"/>
                          <button onClick={()=> {this.secondPhoto.click()}} className="mt-4 btn btn-info">Select Second Photo</button>
                      </Col>


                      <Col className="text-center" md={4} sm={12} lg={4}>

                          <CircularProgressbar
                              className="CircularProgressbar"
                              value={this.state.distance}
                              maxValue={1}
                              text={`${(this.state.distance * 100).toFixed(2)}%`}
                              styles={{
                                  path: {
                                      stroke: `#d9534f`,
                                  },

                              }}
                          />;



                          <CircularProgressbar
                              className="CircularProgressbar"
                              value={this.state.similarity}
                              maxValue={1}
                              text={`${(this.state.similarity * 100).toFixed(2)}%`}
                              styles={{
                                  path: {
                                      stroke: `#5cb85c`,
                                  },

                              }}
                          />;


                          <h4 className='text-info pt-2'>Matching Result: {this.state.result}</h4>
                          <button onClick={this.FaceMatchingResult}  className="mt-4 btn btn-info">Get Result</button>
                      </Col>

                  </Row>
              </Container>

                { this.popupMenu() }
                { this.setLoader() }
            </Fragment>
        );
    }
}

export default FaceMatch;
